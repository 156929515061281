<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-toolbar
        dense
        card
      >
        <v-menu
          v-model="menu"
          lazy
          close-on-content-click
          transition="scale-transition"
          full-width
          min-width="290px"
        >
          <v-text-field
            slot="activator"
            v-model="monthOf"
            prepend-icon="event"
            readonly
          />
          <v-date-picker
            v-model="monthOf"
            :max="maxDate"
            :allowed-dates="allowedMonths"
            min="2018-01"
            type="month"
            year-icon="date_range"
          />
        </v-menu>
        <v-spacer />
        <black-pins v-model="pin" />
      </v-toolbar>
      <v-card-title>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          solo-inverted
          clearable
          hide-details
        />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :pagination.sync="pagination"
        :search="search"
        class="px-1 pb-1"
        item-key="name"
      >
        <template
          slot="headers"
          slot-scope="props"
        >
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              :class="headerClass(header)"
              @click="changeSort(header.value)"
            >
              {{ header.text }}
              <v-icon
                v-if="header.sortable"
                small
              >
                arrow_upward
              </v-icon>
            </th>
          </tr>
        </template>
        <template
          slot="items"
          slot-scope="props"
        >
          <td class="text-xs-left">
            {{ props.item.date }}
          </td>
          <td class="text-xs-left">
            {{ props.item.in > 0 ? props.item.source : props.item.target }}
          </td>
          <td class="text-xs-left">
            {{ props.item.action }}
          </td>
          <td class="text-xs-right">
            {{ props.item.in }}
          </td>
          <td class="text-xs-right">
            {{ props.item.out }}
          </td>
        </template>
        <template slot="no-data">
          {{ noDataText }}
        </template>
        <template slot="no-result">
          <v-alert
            :value="true"
            color="error"
            icon="warning"
          >
            {{ noResultText }}
          </v-alert>
        </template>
        <template slot="footer">
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td class="text-xs-right primary--text">
              Total:
            </td>
            <td class="text-xs-right primary--text">
              {{ totalIn }}
            </td>
            <td class="text-xs-right primary--text">
              {{ totalOut }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'

const reportTitle = 'Black PIN Transactions'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
    BlackPins: () => import(/* webpackChunkName: "blackPins" */ '@/components/BlackPins'),
  },
  data () {
    return {
      pin: { text: 'B89DB', value: 'B89DB' },
      allowedMonths: function (month) {
        const ym = month.split('-')
        const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
        const fromDate = DateTime.local(2019, 9, 1).setZone('Asia/Kuala_Lumpur')
        const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

        return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
      },
      breadcrumbs: [
        {
          text: 'Dealer Kits', disabled: false, to: '/dealer_kits',
        },
        {
          text: 'Black', disabled: false, to: '/black_kits',
        },
        {
          text: 'Black PIN', disabled: false, to: '/black_pin_kits',
        },
        {
          text: reportTitle, disabled: true,
        },
      ],
      expand: true,
      headers: [
        {
          align: 'text-xs-left',
          sortable: true,
          text: 'Date',
          value: 'date',
        },
        {
          align: 'text-xs-left',
          sortable: true,
          text: 'Source/Target',
          value: 'target',
        },
        {
          align: 'text-xs-left',
          sortable: true,
          text: 'Action',
          value: 'action',
        },
        {
          align: 'text-xs-right',
          text: 'In',
          value: 'in',
        },
        {
          align: 'text-xs-right',
          text: 'Out',
          value: 'out',
        },
      ],
      items: [
      ],
      loading: false,
      maxDate: '',
      menu: false,
      monthOf: null,
      noDataText: null,
      noResultText: 'No data that fits that criteria.',
      pagination: {
        descending: true,
        sortBy: 'name',
      },
      search: '',
      title: reportTitle,
      totalIn: 0,
      totalOut: 0,
    }
  },
  watch: {
    monthOf: function (val) {
      this.getBlackPinTransactions(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.monthOf = now.toFormat('yyyy-LL')
  },
  methods: {
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
    getBlackPinTransactions (month) {
      const params = createGetParams({
        month: month,
        pin: this.pin.value,
      })
      this.loading = true
      this.$rest.get('getBlackPinTransactions.php', params)
        .then(function (response) {
          this.items = response.data
          this.totalIn = this.totalOut = 0
          response.data.forEach(item => {
            this.totalIn += Number(item.in)
            this.totalOut += Number(item.out)
          })
          this.noDataText = "There's nothing to show."
          this.loading = false
        }.bind(this)).catch(error => {
          this.noDataText = error.message + ': ' + error.response.data
          this.loading = false
        })
    },
    headerClass (header) {
      const headerClass = ['column']
      header.align && headerClass.push(header.align)
      header.sortable && headerClass.push('sortable')
      header.sortable && (this.pagination.descending
        ? headerClass.push('desc') : headerClass.push('asc'))
      header.sortable && header.value === this.pagination.sortBy &&
        headerClass.push('active')

      return headerClass
    },
  },
}
</script>
